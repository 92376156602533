import React from 'react'
import '../terms & condition/term.css'
export default function term() {
  return (
    <div className='d4'>
      <div className="parent4">
        <div className='content4'>
          
        </div>
      </div>

    </div>
  )
}
