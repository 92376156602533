import React from 'react'
import styles from '../explore/style.module.css'
function Explore() {
    return (
        <div>
            <h1 className={styles.heading}>Hello user</h1>
        </div>
    )
}

export default Explore
